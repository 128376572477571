<template>
  <div>
    <template v-if="show">
      <transition name="fade-three" mode="out-in">
        <router-view />
      </transition>
      <van-tabbar z-index='1000' route inactive-color='#000000' active-color='#00C782' safe-area-inset-bottom>
        <van-tabbar-item replace :to="item.path" icon="home-o" v-for="item in tabBarArr" class="tab-item" :class="{ bigItem: item.routeName === $route.name }" :key="item.id">
          <template #icon="props">
            <img :src="props.active ? item.selected : item.normal" />
          </template>
          {{ item.name }}
        </van-tabbar-item>
      </van-tabbar>
    </template>
    <van-empty v-if="!show" image="error" description="系统维护中" />
  </div>
</template>

<script>
import diffusion from "@/mixin/diffusion.js"
export default {
  name: "tabbar",
  mixins: [diffusion],
  data() {
    return {
      show: 1,
      tabBarArr: [
        {
          id: 0, //ID号
          name: "首页", //名称
          path: "/",
          routeName: "/", //路由名称
          normal: require("@/assets/img/i.png"), //未选中时图标
          selected: require("@/assets/img/a@2x.png"), //选中时图标
        },
        {
          id: 1, //ID号
          name: "绩效商城", //名称
          path: "/shop",
          routeName: "shop", //路由名称
          normal: require("@/assets/img/mm@2x.png"), //未选中时图标
          selected: require("@/assets/img/kk@2x.png"), //选中时图标
        },
        {
          id: 2, //ID号
          name: "福利商城", //名称
          path: "/shopa",
          routeName: "shopa", //路由名称
          normal: require("@/assets/img/nn@2x.png"), //未选中时图标
          selected: require("@/assets/img/ll@2x.png"), //选中时图标
        },
        {
          id: 3, //ID号
          name: "资讯", //名称
          path: "/news",
          routeName: "news", //路由名称
          normal: require("@/assets/img/e@2x.png"), //未选中时图标
          selected: require("@/assets/img/b@2x.png"), //选中时图标
        },
        {
          id: 4, //ID号
          name: "我的", //名称
          path: "/mine",
          routeName: "mine", //路由名称
          normal: require("@/assets/img/ma.png"), //未选中时图标
          selected: require("@/assets/img/m.png"), //选中时图标
        },
      ],
    }
  },
  methods: {
    switchTo(routeName) {
      if (this.$route.name === routeName) return
      this.$router.replace(routeName)
    },
    getdata() {
      this.$post({
        url: "/app/auth/open",
        success: (res) => {
          // this.show = Number(res.data.is_open)
        },
        tip: () => {},
      })
    },
  },
  mounted() {
    this.getdata()
    if (this.$refs.btn) {
      this.$refs.btn.map((item) => {
        item.addEventListener("touchstart", (e) => {
          this.diffusion(item, e.touches[0].clientX, e.touches[0].clientY)
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.fade-three-enter-active,
.fade-three-leave-active {
  transition: opacity 0.15s;
}
.fade-three-enter, .fade-three-leave-to /* .fade-three-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.footBox {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  .navFoot {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    .tab-item {
      position: relative;
      flex: 1;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 8vh;
      align-items: center;
      > img {
        width: 7vw;
        height: 7vw;
      }
      > span {
        font-size: 3.2vw;
        font-weight: 400;
        color: #999999;
      }
      .on {
        color: $theme;
      }
    }
    .bigItem {
      z-index: +1;
      > img {
        animation: animateImg 0.9s ease-in-out;
      }
      @keyframes animateImg {
        0% {
          transform: translate3d(0, 0, 0);
        }
        25% {
          transform: translate3d(0, -40%, 0);
        }
        75% {
          transform: translate3d(0, 15%, 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
// 判断iphoneX 将 footerBox 的 padding-bottom 填充到最底部
@supports (bottom: env(safe-area-inset-bottom)) {
  .footBox {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@supports (bottom: constant(safe-area-inset-bottom)) {
  .footBox {
    padding-bottom: constant(safe-area-inset-bottom);
  }
}

// vant tabber
/deep/.van-tabbar {
  height: 8vh;
  .van-tabbar-item__icon > img {
    width: 7vw;
    height: 7vw;
  }
}
</style>