export default {
    methods: {
        /**
         * 点击扩散水纹
         * @param {Element} target 需要加动画的元素 Dom
         * @param {Number} clientX 手指点击相对于屏幕的 x 坐标（clientX)
         * @param {Number} clientY 手指点击相对于屏幕的 y 坐标（clientY)
         * @param {String} click_color 水纹颜色, 默认 rgba(0, 0, 0, 0.1)
         */
        diffusion(target, clientX, clientY, click_color = "rgba(0, 0, 0, 0.1)") {
            const canvas = document.createElement("canvas"),
                w = target.offsetWidth,
                h = target.offsetHeight,
                x = clientX - target.getBoundingClientRect().x,
                y = clientY - target.getBoundingClientRect().y;
            let i = 0;

            canvas.width = w;
            canvas.height = h;

            Object.assign(canvas.style, {
                position: "absolute",
                top: 0,
                opacity: 1,
                transition: "0.5s opacity",
                left: 0
            });

            target.appendChild(canvas);

            let ctx = canvas.getContext("2d");
            ctx.fillStyle = click_color;

            (function draw() {
                ctx.clearRect(0, 0, w, h);
                ctx.beginPath();
                ctx.arc(x, y, i, 0, 2 * Math.PI, false);
                ctx.fill();

                i += w / 20;
                const dim = w === h ? w * 1.412 : Math.sqrt((w * w) + (h * h))
                if (i <= dim)
                    window.requestAnimationFrame(() => {
                        draw();
                    })
                else {
                    canvas.style.opacity = 0
                    setTimeout(() => {
                        ctx.clearRect(0, 0, w, h);
                        target.removeChild(canvas);
                    }, 500)
                }
            })();
        }
    }
}